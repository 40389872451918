import React from "react"
import {I18NextWrapper} from "../loaders/i18nextInit";
import "../styles/index.css"
import {Loading} from "../components/Loading";
import TermsComponent from "../components/Terms"

const Terms = () => {
    return (
        <>
            <I18NextWrapper loading={<Loading/>}>
                <TermsComponent/>
            </I18NextWrapper>
        </>
    )
}

export default Terms
