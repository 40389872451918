export const config = {
    links: {
        video: 'https://www.youtube.com/embed/co6DgNvniRs?autoplay=1',
        facebook: 'http://facebook.com',
        twitter: 'http://twitter.com',
        instagram: 'http://instagram.com',
        appStore: 'https://apps.apple.com/ro/app/id1511186313',
        googlePlay: 'https://play.google.com/store/apps/details?id=com.persidius.ara&gl=RO',
        press: './assets/ara_press_kit.zip',
        contact: 'mailto:ara@persidius.com',
        terms: './terms/',
        privacy: './privacy/',
        drive: {
            terms: 'https://docs.google.com/document/d/e/2PACX-1vQaGsP6vseUp3UB2QUgNaAWz6zKEsBLGKkMDu5TdBOq_r8oB1JKH5vnq8LryYu774Z2c9otUh-D7TSW/pub?embedded=true',
            privacy: 'https://docs.google.com/document/d/e/2PACX-1vT1PN0KVIilYZNkgayQmzEmtllA9jy02Kw3AWv47UgNSgtib3PvBT7etDlXQDC-N-0n7GHPteQlcsHc/pub?embedded=true',
            dps: 'https://docs.google.com/document/d/e/2PACX-1vT2tsCmiziYAHE8ivVGoIT3f-bpWO63y4GoHKH8uz3T5tzQpZZGwCFBrL8vUlooWoBW91dGDIF2uunf/pub?embedded=true',
            concurs: 'https://docs.google.com/document/d/e/2PACX-1vTAuUkQpMpvEpkm1jDwGEI1obzeqqYtL7xyEr-YW0Yky8erdLZ18iStR23j0uAvw89-5e53EVPXOtR6/pub?embedded=true'
        }
    }, i18next: {
        loadPath: process.env.I18NEXT_LOAD_PATH || './i18n/{{lng}}/{{ns}}.json'
    }

}
